import axios from "axios";
import authHeader from "@/services/auth.header";
const API_URL = process.env.VUE_APP_API_URL;

class listinoService {
  getListini(params) {
    return (
      axios
        .get(API_URL + 'pricelist/', {
          headers: authHeader(), params: params
        })
    );
  }
  newListino(payload) {
    return axios.post(API_URL + "pricelist/", payload, {
      headers: authHeader(),
    });
  }
  editListino(itemId, edited) {
    return (
      axios
        .put(API_URL + `pricelist/${itemId}/`, edited, {
          headers: authHeader(),
        })
    );
  }
  deleteListino(itemId) {
    return axios.delete(API_URL + `pricelist/${itemId}/`, {
      headers: authHeader(),
    });
  }
  getListiniItems(params) {
    return (
      axios
        .get(API_URL + 'pricelist_items/', {
          headers: authHeader(), params: params
        })
    );
  }
  getListiniItemsLight(params) {
    return axios.get(API_URL + 'pricelist_items/', { headers:authHeader(), params:params})
  }
  getListiniItemsById(id) {
    return (
      axios
        .get(API_URL + `pricelist_items/${id}`, {
          headers: authHeader()
        })
    );
  }
  newListinoItem(payload) {
    return axios.post(API_URL + "pricelist_items/custom_create/", payload, {
      headers: authHeader(),
    });
  }
    newListinoItemFromWineMaker(payload) {
    return axios.post(API_URL + "pricelist_items/custom_create/", payload, {
      headers: authHeader(),
    });
  }
  deleteListinoItem(itemId) {
    return axios.delete(API_URL + `pricelist_items/${itemId}/`, {
      headers: authHeader(),
    });
  }
  editListinoItem(itemId, edited) {
    return (
      axios
        .put(API_URL + `pricelist_items/${itemId}/`, edited, {
          headers: authHeader(),
        })
    );
  }
  addWineToCell(payload) {
    return axios.post(API_URL + `warehouse_items/create_from_order_item/`, payload, { headers: authHeader()})
  }
  simpleAddWineToCell(payload) {
    return axios.post(API_URL + 'warehouse_items/', payload, { headers: authHeader() })
  }
  async getGiacenza(params) {
    return await axios.get(API_URL + `pricelist_items/`, {headers: authHeader(), params:params})
  }
}

export default new listinoService()
