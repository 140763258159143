import axios from "axios";
import authHeader from "@/services/auth.header";
const API_URL = process.env.VUE_APP_API_URL;

class wineServices {
  getVini(params) {
    return axios.get(API_URL + 'wine/', {
          headers: authHeader(), params: params
        })
  }

  async getVino(id) {
    return await axios.get(API_URL + `wine/${id}`, {
      headers: authHeader()
    })

  }
  newVino(payload) {
    return axios.post(API_URL + "wine/", payload, {
      headers: authHeader(),
    });
  }
  editVino(payload) {
    return (
      axios
        .put(API_URL + `wine/${payload.id}/`, payload, {
          headers: authHeader(),
        })
    );
  }
  uploadImage(formData, onUploadProgress) {
    return axios.post(API_URL + 'images/', formData, {
      headers: {
        Authorization: authHeader().Authorization,
        "Content-Type": "multipart/form-data",
        // "X-CSRFToken": ""
      },
      onUploadProgress
    } )

  }
  deleteVino(itemId) {
    return axios.delete(API_URL + 'wine/'+ itemId + "/", {
      headers: authHeader(),
    });
  }
  getCountry() {
    return (
      axios
        .get(API_URL + `country/`, {
          headers: authHeader()
        })
    );
  }
  getProvince() {
    return (
      axios
        .get(API_URL + `wine/get_province/`, {
          headers: authHeader()
        })
    );
  }
  getRegion() {
    return (
      axios
        .get(API_URL + `region/`, {
          headers: authHeader()
        })
    );
  }
  deleteImage(id) {
    return axios.delete(API_URL + 'images/' + id + '/',   {
      headers: authHeader(),
    })
  }
  getStore(store) {
    return axios.get(API_URL + `store/${store}/`, {
      headers: authHeader(),
    })
  }
  getStoresInfo() {
    return axios.get(API_URL + 'store/', {
      headers: authHeader(),
    })
  }
  sendFileViaEmail(payload) {
    return axios.post(API_URL + 'order/send_order_via_email/', payload, {
      headers: {
        Authorization: `Token ${this.$store.state.user.token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  get_stats() {
    return axios.get(API_URL + "stats", {
      headers: authHeader(),
    })
  }
  async getImage(params) {
    return await axios.get(API_URL + `wine/${params.pk}/get_immagini`, {headers:authHeader()})
  }
  async saveImage(payload) {
    return await axios.post(API_URL + `images/save_image/`, payload, {headers: authHeader()})
  }
}

export default new wineServices();
