<template>
  <div class="justify-center">
    <v-dialog
        v-model="dialog"
        width="600"
        transition="dialog-top-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs"
            v-on="on"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bolder text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3"
            small
        >Aggiungi Bottiglia al Rack
        </v-btn>
      </template>
      <template class="mx-4">
        <v-card>
          <v-toolbar
              color="pink"
              dark
          >Aggiungi Bottiglia
          </v-toolbar>
          <v-card-text>
            <v-autocomplete
                v-model="vinoListini"
                :loading="autocompleteLoading"
                :items="listiniItems"
                item-text="wine_name"
                item-value="id"
                :search-input.sync="search"
                cache-items
                class="mx-4"
                flat
                hide-no-data
                hide-details
                label="Cerca un vino..."
                color="pink"
            ></v-autocomplete>
            <v-spacer class="mb-5"/>
            <v-select
                v-model="magazzino"
                @click="getWarehouse"
                flat
                label="Scegli il Magazzino"
                color="pink"
                :items="magazzini"
                item-text="name"
                item-value="id"
            ></v-select>
            <v-select
                v-if="magazzino"
                v-model="rack"
                @click="getRack"
                :items="racks"
                label="Scegli il Rack"
                color="pink"
                dense
                item-text="name"
                item-value="id"
            ></v-select>
            <v-select
                v-model="item.cell"
                v-if="rack"
                @click="getCellRack"
                dense
                color="pink"
                label="Scegli la Cella"
                :items="cell_racks"
                item-text="name"
                item-value="id"
            ></v-select>
            <v-text-field
                v-if="item.cell"
                dense
                label="Inserisci la Q.tà"
                v-model="item.quantity"
            >
            </v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="pink"
                dark
                small
                @click="dialog = false"
            >
              Annulla
            </v-btn>
            <v-btn
                dark
                small
                color="indigo"
                @click="save"
            >
              Aggiungi
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import listinoService from "@/services/listino.service";
import WarehouseService from "@/services/warehouse.service";

export default {
  props: [
    'box'
  ],
  data() {
    return {
      vinoListini: null,
      listiniItems: [],
      dialog: false,
      search:"",
      racks: [],
      rack: null,
      magazzini: [],
      magazzino: null,
      cell_racks: [],
      cell: null,
      item: {
        cell: null,
        quantity: null,
        id: null
      },
      filter: {
        ricercaVino: null
      },
      wine: {},
      autocompleteLoading: false
    }
  },
  mounted() {
    this.getListiniItemsMinimal()
  },
  methods: {
    getListiniItems() {
      this.autocompleteLoading = true
      const params = {
        search: this.filter.ricercaVino,
      };
      listinoService.getListiniItems(params).then((resp) => {
        this.listiniItems = resp.data.results;
        this.autocompleteLoading = false
      });
    },
    getListiniItemsMinimal() {
      this.autocompleteLoading = true
      const params = {
        search: this.filter.ricercaVino,
        page_size: 1500,
        minimal: true
      };
      listinoService.getListiniItems(params).then((resp) => {
        this.listiniItems = resp.data.results;
        this.autocompleteLoading = false
      });
    },
    getWarehouse() {
      const params = {
        store: this.$store.state.auth.account.store[0]
      }
      WarehouseService.getMagazzini(params)
          .then(resp => {
            this.magazzini = resp.data.results
          })
          .catch()
    },
    getRack() {
      const params = {
        warehouse: this.magazzino
      }
      WarehouseService.getRacks(params)
          .then(resp => {
            this.racks = resp.data.results
          })
          .catch()
    },
    getCellRack() {
      const params = {
        rack: this.rack
      }
      WarehouseService.getCellRack(params)
          .then(resp => {
            this.cell_racks = resp.data.results
          })
          .catch()
    },
    addQuantity() {
      this.item.quantity++
    },
    removeQuantity() {
      this.item.quantity--
    },
    save() {
      const payload = {
        p_list_item: this.vinoListini,
        quantity: this.item.quantity,
        cell_rack: this.item.cell,
        wine: this.wine.wine,
      }
      listinoService.simpleAddWineToCell(payload)
          .then(() => {
            this.$dialog.message.success("Vino Inserito con Correttamente", {
              type: "success",
              rounded: true,
              position: "top-right",
              color: "green",
              outlined: false,
              timeout: 1000,
            })
            this.dialog = false
          })
          .catch(() => {
            this.$dialog.notify.warning("Errore inserimento Vino", {
              type: "warning",
              rounded: true,
              position: "top-right",
              color: "red accent-3",
              outlined: false,
              timeout: 1000,
            })
          })
    }
  },
  watch: {
    "filter.ricercaVino"(val) {
      if (val) {
        this.getListiniItems()
      }
    },
    vinoListini(value) {
      console.log(value)
      listinoService.getListiniItemsById(value).then(resp => {
        this.wine = resp.data
        console.log(resp.data)
      })
    }
    // vinoListini(value) {
    //   this.listiniItems.filter(item => item.full_name.includes(value))
    //   console.log(value)
    // }
  },
}
</script>

<style scoped>

</style>